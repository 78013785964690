import React, { useEffect } from "react";
import { useState, useCallback } from "react";
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ActiveProcessingPage from "./ezev-components/ActiveProcessingPage";
import LandingPage from "./ezev-components/LandingPage";
import TableController from "./ezev-components/TableController";
import SingleVclController from "./ezev-components/SingleVclController";
import AssumptionsView from "./ezev-components/AssumptionsView";
import "../component-css/ezev-stylesheets/ezev-tables-v6.css";
import "../component-css/ezev-stylesheets/ezev-master-stylesheet.css";
import {getFuelType, vehicleIdDisplay} from "./ezev-components/HelperFunctions";
import { processApiResponse } from "./ezev-components/utils/ConformUnits";
import { calcEffectiveBattery } from "./ezev-components/HelperFunctions";
import { convertISOToExcelSerial } from "./ezev-components/FormatExcelData";

const USABLE_BATTERY_PCT = 0.9;
const MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED = 5;
const UPDATE_MILLISECONDS = 1000;

export default function Ezev(props) {
    const { apiURL, dbName, dbDisplayName, dbSettings, user, history, products } = props;

    const [activity, setActivity] = useState(null);
    const [candidates, setCandidates] = useState([]);
    const [groups, setGroups] = useState(null);
    const [authorizedGroups, setAuthorizedGroups] = useState(null);
    const [populatedGroups, setPopulatedGroups] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [groupMinDate, setGroupMinDate] = useState(null);
    const [groupMaxDate, setGroupMaxDate] = useState(null);
    const [hasReceivedDates, setHasReceivedDates] = useState(null);
    const [isAnalyticsProcessing, setIsAnalyticsProcessing] = useState(null);
    const [isAggregateProcessing, setIsAggregateProcessing] = useState(null);
    const [processingCountdownFinished, setProcessingCountdownFinished] = useState(null);
    const [fleetEzevMetrics, setFleetEzevMetrics] = useState(null);
    const [groupEzevMetrics, setGroupEzevMetrics] = useState(null);
    const [totalGroupEzevMetrics, setTotalGroupEzevMetrics] = useState(null);
    const [groupRecommendedCount, setGroupRecommendedCount] = useState(null);
    const [selectedVehicleCategory, setSelectedVehicleCategory] = useState("All Vehicles");
    const [parkingData, setParkingData] = useState(null);
    const [apiError, setApiError] = useState(null);
    const [vehicleCount, setVehicleCount] = useState(null);

    // Fetch fxns
    const getGroupAccess = useCallback(() => {
        if (!user) return;
        fetch(
          `${apiURL}getGroupAccess?ident=${dbName}&username=${user.email}`,
          { headers: { Authorization: `Bearer ${user.token}` } }
        )
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            let groups = [];
            if (data.groups) groups = data.groups;
            if (groups.length === 0) {
                setPopulatedGroups(["No Groups"]);
            }
            else {
              groups.forEach((g) => {
                groups.push(g.group_id);
              });
              return (groups);
            }
          })
          .then((groups) => {
            fetch(
              `${apiURL}getGroupChildren?ident=${dbName}`,
              { headers: { Authorization: `Bearer ${user.token}` } }
            )
              .then((res) => {
                return res.json();
              })
              .then((r) => {
    
                if (groups) {
                  r.data.forEach((g) => {
                    if (groups.indexOf(g.id) === -1) groups.push(g.id);
                  });
                }
                setAuthorizedGroups(groups);
              });
          });
    }, [apiURL, user, dbName])

    const getCandidates = useCallback(() => {
        var url = `${apiURL}getCandidates`;
        var queries = `?ident=${dbName}`;
        return fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${user.token}` } })
          .then(res => res.json())
          .then(data => {
            data['data'].forEach((u) => {
              u.upfits = u.upfits === null ? [] : u.upfits
              if(u.usable_kwh === null){
                if(u.battery_capacity !== null && u.battery_capacity !== undefined){
                  // note: this logic is also present in analytics. If it is changed in one place, it needs to be changed in the other.
                  // replace with 90% of battery capacity (incl where zero)
                  u.usable_kwh = u.battery_capacity * USABLE_BATTERY_PCT;
                }
              }
              // conform units
              u = processApiResponse(user.userSettings, u);
            })
            setCandidates(data['data']);
          })
    }, [apiURL, dbName, user])

    const getGroups = useCallback((ld = false, all = true) => {
        if (!authorizedGroups) return;
        const sortByName = function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        };
        fetch(`${apiURL}getGroups/${dbName}?is_ld=${ld ? "true" : "false"}&all_categories=${all ? "true" : "false"}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        })
          .then((res) => {
            return res.json();
          })
          .then((r) => {
            let arr = r.data;
            arr.sort(sortByName);
            arr = arr.filter((g) => {
              if (g.count > 0) return g;
              else return null;
            });
            const idx = arr.findIndex((o) => { return o.id === 'swt-vehicles' });
            if (idx > -1) {
              const g = arr[idx];
              arr.splice(idx, 1)
              arr.unshift(g);
            }
            var newSelectedGroup = {}
            if (arr.length === 0) {
              arr = ["No Groups"]
              newSelectedGroup = { selectedGroup: "No Groups" }
            }
            else {
                newSelectedGroup = arr[idx]; // Assign selected group to default all vehicles on load
            }
            //Update the selected group to no groups if no groups were returned
            //Doing this avoids all of the refetching associated with a full handleGroupChange
            setGroups(arr);
            setPopulatedGroups(arr);
            setSelectedGroup(newSelectedGroup);
          });
    }, [dbName, apiURL, authorizedGroups, user])

    const getActivity = useCallback(() => {
        //Abort fetch if the category isn't selected yet
        if (!selectedVehicleCategory) {
          return
        }
        const {ld, all_categories} = getVehicleCategoryFlags(selectedVehicleCategory);
        var group = selectedGroup
        // fetch data for all vcls
        var url = `${apiURL}getVehicleResults`;
        var queries = `?dbName=${dbName}`;
    
        queries += `&groupId=${group.id}`;
        queries += `&is_ld=${ld}&all_categories=${all_categories}`;
    
        fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${user.token}` } })
          .then(res => res.json())
          .then(data => {
            url = `${apiURL}getVehicleUpfits`;
            queries = `?dbName=${dbName}`;
            fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${user.token}` }})
              .then(res => res.json())
              .then(upfits => {
                data['data'].forEach((v) => {
                  // get upfits
                  v.upfits = upfits['data'].filter(u => u.vin === v.vin);
                  v.has_upfits = v.upfits.length > 0 ? 'True' : 'False';
                  v.required_upfits = v.upfits.filter(e => e.required);
                  v.optional_upfits = v.upfits.filter(e => !e.required);
    
                  // get vehicle effective usable kWh for PDFs
                  // TODO: match candidates to vehicles on ID initially and not YMM
                  let cand = candidates.find(c => c.ymm === v.scored_on);
                  // calc effective battery capacity if candidate match is found and usable battery exists
                  if (cand && cand.usable_kwh !== undefined && cand.usable_kwh !== null) {
                    v.rec_effective_battery_kwh = calcEffectiveBattery(dbSettings.charge_to_ld, dbSettings.discharge_to_ld, dbSettings.charge_to_mdhd, dbSettings.discharge_to_mdhd, v.is_ld, cand.usable_kwh);
                  } else {
                    v.rec_effective_battery_kwh = '-';
                  }
    
                  
                  // Convert min/max timestamps from ISO to Excel Serial, to ensure dates are still properly sortable in spreadsheet download.
                  // These variables need to be assigned before the vehicle's data is ran through processAPIResponse since all values containing '_ts' are are then converted to strings matching the user's date format, instead of the original timestamp
                  let excel_min_ts = convertISOToExcelSerial(v.min_ts);
                  let excel_max_ts = convertISOToExcelSerial(v.max_ts);
    
                  // Conform units
                  v = processApiResponse(user.userSettings, v);
    
                  // Once the API response is processed, add the serial-formatted timestamps back to the vehicle object for use in excel downloads
                  v.excel_sortable_min_ts = excel_min_ts;
                  v.excel_sortable_max_ts = excel_max_ts;
                })
                setActivity(data['data'])
                setVehicleCount(data['data'].length)
              });
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
          });
    }, [apiURL, dbSettings, user, candidates, selectedGroup, dbName, selectedVehicleCategory])

    const getGroupEzevMetrics = useCallback(() => {
        const url = `${apiURL}getGroupEzevMetrics?ident=${dbName}&group=${selectedGroup.id}`;
        fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
          .then(res => res.json())
          .then((data) => {
            data['data'].forEach((d) => {
              d = processApiResponse(user.userSettings, d);
            })
            const obj = data.data;
            setGroupEzevMetrics(obj);
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
          });
    }, [dbName, selectedGroup, user, apiURL]);
    
    const getTotalGroupEzevMetrics = useCallback(() => {
        const url = `${apiURL}getTotalGroupMetrics?ident=${dbName}&group=${selectedGroup.id}`;
        fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
          .then(res => res.json())
          .then((data) => {
            const obj = processApiResponse(user.userSettings, data.data[0])
            setTotalGroupEzevMetrics(obj);
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
          });
    }, [dbName, selectedGroup, user, apiURL]);

    const getEzevTemperatureMetrics = useCallback(() => {
        if(!selectedVehicleCategory) return;
        const {ld, all_categories} = getVehicleCategoryFlags(selectedVehicleCategory);
        const url = `${apiURL}getEzevTemperatureMetrics?ident=${dbName}&group=${selectedGroup.id}&is_ld=${ld}&all_categories=${all_categories}`;
        fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
          .then(res => res.json())
          .then((data) => {
            const obj = processApiResponse(user.userSettings, data.data[0]);
            setFleetEzevMetrics(obj);
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
          });
    }, [apiURL, selectedGroup, selectedVehicleCategory, dbName, user]);
    
    const getGroupMetrics = useCallback(() => {
        const url = `${apiURL}getGroupMetrics?ident=${dbName}&group=${selectedGroup.id}`;
        fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
          .then(res => res.json())
          .then((data) => {
            const obj = processApiResponse(user.userSettings, data.data);
            setGroupMinDate(obj.min_ts);
            setGroupMaxDate(obj.max_ts);
            setHasReceivedDates(true);
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
          });
    }, [apiURL, selectedGroup, dbName, user]);

    const getGroupRecommendedCount = useCallback(() => {
        if (!selectedVehicleCategory) return;
        const {ld, all_categories} = getVehicleCategoryFlags(selectedVehicleCategory);
        const url = `${apiURL}getGroupRecommendedCount?ident=${dbName}&group=${selectedGroup.id}&is_ld=${ld}&all_categories=${all_categories}`;
        fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
          .then(res => res.json())
          .then((data) => {
            const obj = data.data[0];
            setGroupRecommendedCount(obj.ev_recs);
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
          });
    }, [apiURL, selectedGroup, selectedVehicleCategory, dbName, user]);

    
    const getParkingData = useCallback(() => {
        fetch(`${apiURL}getEzevParkingLocations?dbName=${dbName}`,
            {headers: { Authorization: `Bearer ${user.token}` }
        })
        .then((resp)=>resp.json())
        .then((data) => {
            const parkingData = {};
            data.data.forEach((p)=>{
                if(!parkingData[p.vin]){
                    parkingData[p.vin] = [];
                }
                const arr = parkingData[p.vin];
                arr.push(p); 
            });
            setParkingData(parkingData);
        })
    }, [apiURL, dbName, user])

    // End of fetch fxns

    // Processing check fxns
    const handleProcessingFinished = useCallback(() => {
        // This fxn is called upon an analytics run completion
        if(!selectedGroup) return; // These fetches are group dependent, abort fetch if selectedGroup is not yet set
        
        // Invalidate state that controls loading state of info card
        setActivity(null);
        setFleetEzevMetrics(null);
        setGroupRecommendedCount(null);

        // Re-fetch analytics dependent data
        getActivity();
        getGroupMetrics();
        getTotalGroupEzevMetrics();
        getGroupEzevMetrics();
        getEzevTemperatureMetrics();
        getGroupRecommendedCount();
      }, [getActivity, getGroupMetrics, getGroupEzevMetrics, getEzevTemperatureMetrics, getTotalGroupEzevMetrics, getGroupRecommendedCount, selectedGroup]);

    // Get the state of aggregate processing and set applicable flag
    const getAggregateProcessingState = useCallback(() => {
        const url = `${apiURL}isAggregateProcessing?dbName=${dbName}`;
        return fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
          .then(res => res.json())
          .then((data) => {
            let processing = data.data[0].aggregate_processing;
            setIsAggregateProcessing(processing);
            return Promise.resolve(processing);
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
            return Promise.reject(err);
          });
    }, [apiURL, dbName, user]);
    
    // Get the state of an analytics run, set applicable flags, and handle redirect/refetch
    const getAnalyticsProcessingState = useCallback((shouldResetState) => {
        const url = `${apiURL}isAnalyticsProcessing?dbName=${dbName}`;
        return fetch(url, {headers: { Authorization: `Bearer ${user.token}` }})
          .then(res => res.json())
          .then((data) => {
            let processing = data.data[0].analytics_processing;
            setIsAnalyticsProcessing(processing);
            if (processing) {
              // return to the homepage
              history.push("/ezev/landing");
            } else {
              // if analytics is not processing, reset the state if we are initializing or the user updated candidates
              // if analytics is not processing, we don't want to reset state if this function was called from the single vehicle controller
              if (shouldResetState) {
                handleProcessingFinished();
              }
            }
            return Promise.resolve(processing);
          })
          .catch((err) => {
            console.error(err);
            setApiError(true);
            return Promise.reject(err);
          });
    }, [apiURL, dbName, user, history, handleProcessingFinished]);

    // Handles analytics run completion and error states if applicable
    const handleProcessingCountdownFinished = useCallback(() => {
        let failures_to_update = 0;
        const intervalId = setInterval(async () => {
          const isProcessing = await getAnalyticsProcessingState(true);
          
          if (!isProcessing) {
            setProcessingCountdownFinished(null);
            clearInterval(intervalId);
          } else {
            failures_to_update+=1;
          }
          
          if (failures_to_update >= MAX_FAILURES_TO_UPDATE_PROCESSING_ALLOWED) {
            clearInterval(intervalId);
            if (isProcessing) {
                setApiError(true);
            }
          }
        }, UPDATE_MILLISECONDS);
    }, [getAnalyticsProcessingState])

    // End of processing check fxns

    // Start of state control fxns

    // Checks aggregate/analytics processing states on load
    useEffect(() => {
      // Note these are self handling functions, no need to consume the response
      // Promise chained together to preserve our order of operations 
      getAggregateProcessingState()
      .then((res) => {
          getAnalyticsProcessingState(true)
          .then((res) => {
          })
      })
    // eslint-disable-next-line
    }, [])

    // Handles calling analytics processing completion logic if processing is complete
    useEffect(() => {
        if(processingCountdownFinished) handleProcessingCountdownFinished();
    }, [handleProcessingCountdownFinished, processingCountdownFinished])
    
    // Fetch calls that are not dependent on groups
    useEffect(() => {
        getGroupAccess();
        getCandidates();
        getParkingData();
    }, [getCandidates, getParkingData, getGroupAccess])

    // Get groups separate of other fetches, to avoid dependency loop
    useEffect(() => {
        getGroups();
    }, [getGroups])

    // Group dependent fetch fxns
    useEffect(() => {
        if(!selectedGroup) return; // These fetches are group dependent, abort fetch if selectedGroup is not yet set

        // Set some state to null if we are currently fetching/refetching
        // Info card landing loading bar checks against this state
        setActivity(null);
        setFleetEzevMetrics(null);
        setGroupRecommendedCount(null);
        getActivity();
        getGroupEzevMetrics();
        getTotalGroupEzevMetrics();
        getEzevTemperatureMetrics();
        getGroupMetrics();
        getGroupRecommendedCount();
    }, [getActivity, getGroupEzevMetrics, getTotalGroupEzevMetrics, getEzevTemperatureMetrics, getGroupMetrics, getGroupRecommendedCount, selectedGroup])

    // End of state control functions

    // Start of helper fxns
    const getVehicleCategoryFlags = (vehicleCategory) => {
        let ld = false;
        let all_categories = false;
        if (vehicleCategory === 'Light Duty') ld = true;
        if (vehicleCategory === 'Medium and Heavy Duty') ld = false;
        if (vehicleCategory === 'All Vehicles') {
          all_categories = true;
        }
        return {"ld": ld, "all_categories": all_categories};
    }

    // Handle group change on control change
    const handleGroupSelect = (e, groupId) => {
        setHasReceivedDates(false);
        let group = groups.filter((g) => {
          return g.id === groupId;
        });
        setSelectedGroup(group[0])
    };
    
    // Handle category change on control change
    const handleVehicleCategorySelect = (e) => {
        setSelectedGroup(groups.find(g => g.id === 'swt-vehicles')); // Reset selected group to all vehicles on category change
        setSelectedVehicleCategory(e.value);
    }
    
    const _filteredActivity = () => {
        if(!activity)return null;
        return activity.map(item => {
          item.asset_id = vehicleIdDisplay(item)
          //Add fuel type and coerce nulls to true for is_ld as per global default
          item.fuel_type = getFuelType(item);
          return item;
        })
    }

    // End of helper fxns

    // Render
    if (isAnalyticsProcessing || isAggregateProcessing || apiError) {
        return (
          // Render our processing page if any of the above conditions
          <ActiveProcessingPage
            dbName={dbName}
            user={user}
            apiURL={apiURL}
            dbDisplayName={dbDisplayName}
            setProcessingCountdownFinished={(value) => setProcessingCountdownFinished(value)}
            apiError={apiError}
            aggregateProcessing={isAggregateProcessing}
          />
        );
    }
    else {  
        // Othwerise render the app
        return (
            <div className="ezev-app">
            <div className="ezev-app-content">
                <Route
                exact
                path="/"
                render={(props) => (
                    <Redirect to={{ pathname: "/ezev" }} />
                )}
                />
                <Route
                exact
                path="/ezev"
                render={(props) => (
                    <Redirect to={{ pathname: "/ezev/landing" }} />
                )}
                />
                <Switch>
                <Route
                    path="/ezev/landing"
                    render={(props) => (
                    <LandingPage
                        dbName={dbName}
                        group={selectedGroup}
                        groupMinDate={groupMinDate}
                        groupMaxDate={groupMaxDate}
                        hasReceivedDates={hasReceivedDates}
                        groupRecommendedCount={groupRecommendedCount}
                        totalGroupEzevMetrics={totalGroupEzevMetrics}
                        groupEzevMetrics={groupEzevMetrics}
                        fleetEzevMetrics={fleetEzevMetrics}
                        dbDisplayName={dbDisplayName}
                        user={user}
                        apiURL={apiURL}
                        groups={populatedGroups}
                        handleGroupSelect={handleGroupSelect}
                        vehicleCount={vehicleCount}
                        selectedGroupId={
                        selectedGroup
                            ? selectedGroup.id
                            : null
                        }
                        selectedVehicleCategory={selectedVehicleCategory}
                        handleVehicleCategorySelect={handleVehicleCategorySelect}
                        activity={JSON.parse(JSON.stringify(_filteredActivity()))}
                        settings={dbSettings}
                        authorizedGroups={authorizedGroups}
                        parkingData={parkingData}
                        candidates={candidates}
                    />
                    )}
                />
                <Route
                    path="/ezev/vehicle-results"
                    exact
                    render={(props) => (
                    <TableController
                        activity={JSON.parse(JSON.stringify(_filteredActivity()))}
                        group={selectedGroup}
                        multipleGroups={groups.length > 1}
                        dbDisplayName={dbDisplayName}
                        userSettings={user.userSettings}
                    />
                    )}
                />

                <Route
                    path="/ezev/vehicle/:vin"
                    exact
                    render={(props) => (
                    <SingleVclController
                        dbName={dbName}
                        dbDisplayName={dbDisplayName}
                        user={user}
                        apiURL={apiURL}
                        localKwh={dbSettings.local_kwh_cost}
                        settings={dbSettings}
                        activity={JSON.parse(JSON.stringify(_filteredActivity()))}
                        candidates={candidates}
                        otherParkingData={parkingData}
                        getAnalyticsProcessingState={getAnalyticsProcessingState}
                        products={products}
                    />
                    )}
                />
                <Route
                    path="/ezev/assumptions"
                    exact
                    render={(props) => (
                    <AssumptionsView
                        dbName={dbName}
                        user={user}
                        dbDisplayName={dbDisplayName}
                        apiURL={apiURL}
                        settings={dbSettings}
                        userSettings={user.userSettings}
                        candidates={candidates}
                    />
                    )}
                />
                </Switch>
            </div>
            </div>
        );
    }
}