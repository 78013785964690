import React from 'react';
import VehiclesTable from './VehiclesTable';
import HeaderTable from './HeaderTable';
import GetColumns from './FormatTable';
import { LinkHome } from './Links';
import { FormatData } from './FormatExcelData'


function TableRenderer(props) {
  const activity = props.activity ?? [];
  return (
    <div>
      < HeaderTable groupName={props.groupName} multipleGroups={props.multipleGroups} dbDisplayName={props.dbDisplayName}/>
      < LinkHome addClass="table" />

      <div className={`ezev-table-wrapper`}>
        <div className="ezev-table-extra">
          <div className="ezev-table-extra-text">
            <span className="ezev-subheading table">Annual projected figures based on tracked period</span>
            <br />
            <span className="ezev-subtext ezEV-body">Click on a header to sort by that metric. Click on a vehicle to see specifics for that vehicle</span>
          </div>
          <div className="ezev-table-download-link-wrapper">
            <div className="ezev-table-download-link" onClick={()=>FormatData(activity, props.dbDisplayName, props.userSettings)}
              target="_blank"
            >
              <p className="ezev-table-download-link-text">Download</p>
            </div>
          </div>
        </div>
        {/* This table component is not set up the same as the 2 other table components. Has different styles and functionality.  */}
        <VehiclesTable columns={GetColumns(props.userSettings)} data={activity} getColumnProps={(column) => {
          return ({
            style: {
              textDecoration: column.id === "asset_id" ? "underline" : null,
              color: column.id === "asset_id" ? "var(--ezev-bluish)" : null
            }
          })
        }} />

        {activity && activity.length > 0 &&
          <div>


            <div className="ezev-table-footnotes ezEV-body" data-testid="noCandidatesInClassInfo-testid">
              <sup>*</sup>Each vehicle is first compared against all electric models within the same vehicle class. If there is an EV within the same class that is a good operational and economic fit, the recommended EV model is provided. If none of the EV models within the same class are a good fit, the vehicle is then compared against all other EV models included in the analysis. The vehicle is then scored against the best fit EV and can receive a recommendation of: Possible Sedan Fit, No Models Fit or No Change. Please see the results page for each vehicle for additional information.
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default TableRenderer;
