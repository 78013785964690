import React, { useState, useEffect } from 'react';
import HeaderSelection from './HeaderSelection';
import { LinkHome } from './Links';
import RatesTable from './RatesTable';
import InfoCardAssumptions from './InfoCardAssumptions';
import { FormatCandidatesData } from './FormatExcelData';
import CustomAssumptionsTable from './CustomAssumptionsTable';
import * as S from "../../styles/ezev-styles/AssumptionsView-styles"
import { processApiResponse, processRateTime } from './utils/ConformUnits';

function AssumptionsView(props) {
  const [selectedBevs, setSelectedBevs] = useState([]);
  const [selectedPhevs, setSelectedPhevs] = useState([]);
  const [selectedIces, setSelectedIces] = useState([]);
  const [currentTab, setCurrentTab] = useState("ices");

  const compareUpfits = (rowA, rowB, id, desc) => {
    if (rowA.values.upfits.length > rowB.values.upfits.length) return -1;
    if (rowA.values.upfits.length < rowB.values.upfits.length) return 1;
    return 0;
  }

  const IceCandidatesTableColumns = [
    {Header: 'Model', accessor: 'ymm', width: 400},
    {Header: 'Class', accessor: 'vehicle_class'},
    {Header: 'Price', accessor: 'net_price'},
    {Header: 'Special Vehicle\nConfigurations', accessor: 'upfits', sortType: compareUpfits}
  ]

  const EVCandidatesTableColumns = [
    {Header: 'Model', accessor: 'ymm', width: 400},
    {Header: 'Class', accessor: 'vehicle_class'},
    {Header: 'Price', accessor: 'net_price', width: 150},
    {Header: 'Total Battery Capacity\n(kWh)', accessor: 'battery_capacity'},
    {Header: 'Special Vehicle\nConfigurations', accessor: 'upfits', sortType: compareUpfits}
  ]

  function filterCandidates() {
    var bevs = [];
    var phevs = [];
    var ices = [];
    props.candidates.forEach(c => {
      if (c.is_bev && c.evsa_selected) bevs.push(c);
      if (c.is_phev && c.evsa_selected) phevs.push(c);
      if (c.is_ice && c.evsa_selected) {
        if (!c.mpg_c) c.mpg_c = '--';
        if (!c.mpg_h) c.mpg_h = '--';
        ices.push(c);
      };
    })
    setSelectedBevs(bevs)
    setSelectedPhevs(phevs)
    setSelectedIces(ices)
  }

  function candidatesByTab(tabName){
    if (tabName === "bevs"){
      return FormatCandidatesData(selectedBevs, [], [], 'BEV', props.dbDisplayName, props.user.userSettings)
    }
    else if (tabName === "phevs"){
      return FormatCandidatesData([], selectedPhevs, [], 'PHEV', props.dbDisplayName, props.user.userSettings)
    }
    else if (tabName === "ices"){
      return FormatCandidatesData([], [], selectedIces, 'ICE', props.dbDisplayName, props.user.userSettings)
    }
    else{
      return []
    }
    
  }
  useEffect(() => {
    filterCandidates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.candidates]);

  //This fetch code is here and not in the rates table in order to avoid pop-in
  //The table's height is unknowable, so the only way to avoid sudden box size shifts is to supply the full table info before the table is rendered.
  const [kwhRates, setKwhRates] = useState([]);
  useEffect(() => {
    fetch(`${props.apiURL}getKwhRates?dbname=${props.dbName}`, { headers: { 'Authorization': `Bearer ${props.user.token}` } })
      .then((res) => res.json())
      .then((kwhRates) => {
          if (kwhRates.data) {
            kwhRates.data.forEach((r) => {
              r = processApiResponse(props.userSettings, r);
              r = processRateTime(props.userSettings, r);
            });
            setKwhRates(kwhRates.data);
        }
      })
      .catch((err) => {
        console.error(err);
        window.alert('ezIO has encountered an error. Please contact your administrator.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (selectedBevs === '' && selectedPhevs === '') return null;
  else {
    return (
      <div>
        <HeaderSelection text='Custom Assumptions' dbDisplayName={props.dbDisplayName} />
          <div className="ezev-content">
            <LinkHome />
              <div className="ezev-assumptions-content">
              <div className="ezev-assumptions-settings">
              <h2 className="ezev-h2">Fleet Input</h2>
              <span className="ezEV-Table-Text-2">These values are the custom inputs provided by the fleet. Some vehicles may have vehicle-specific custom inputs which are listed on the individual vehicle summary page. Please reach out to <S.EmailLink href="mailto:">info@sawatchlabs.com</S.EmailLink> for additional information.</span>
              <InfoCardAssumptions assumptions={props.settings} userSettings={props.userSettings}/>
            </div>

         
        <S.TabRow>
          <S.Tab onClick={() => setCurrentTab("ices")} selected={currentTab === "ices"} data-testid="ice-candidates-tab-testid">ICE Candidates</S.Tab>
          <S.Tab onClick={() => setCurrentTab("bevs")} selected={currentTab === "bevs"} data-testid="bev-candidates-tab-testid">BEV Candidates</S.Tab>
          <S.Tab onClick={() => setCurrentTab("phevs")} selected={currentTab === "phevs"} data-testid="phev-candidates-tab-testid">PHEV Candidates</S.Tab>
          <S.Tab onClick={() => setCurrentTab("rates")} selected={currentTab === "rates"} data-testid="rates-tab-testid">Electricity Rates</S.Tab>
          <S.TabFill />
        </S.TabRow>
        {currentTab === "ices" &&
        <S.TabInfo>      
          <div className="ezev-ice-table-wrapper">
            <h2 className="ezev-h2">Vehicles Selected</h2>
              <div className="ezev-tables-explanation-wrapper">
                <div className="ezev-tables-explanation ezEV-Table-Text-2">The following table list the details for each internal combustion engine (ICE) vehicle considered in the analysis.</div>
                <div className="ezev-table-download-link-wrapper">
                <div className="ezev-table-download-link" onClick={()=>candidatesByTab(currentTab)}
                     target="_blank"
                >
                  <p className="ezev-table-download-link-text">Download</p>
                </div>
                </div>
                </div>
                <CustomAssumptionsTable
                  data={selectedIces}
                  columns={IceCandidatesTableColumns}
                  tableHeader={'Internal Combustion Engine Vehicles'}
                  userSettings={props.userSettings}
                />
          </div>
        </S.TabInfo>
        }
        {currentTab === "bevs" &&
        <S.TabInfo>       
        <div className="ezev-bev-table-wrapper">
          <h2 className="ezev-h2">Vehicles Selected</h2>
          <div className="ezev-tables-explanation-wrapper">
            <div className="ezev-tables-explanation ezEV-Table-Text-2">The following table list the details for each battery electric vehicle (BEV) considered in the analysis.</div>
            <div className="ezev-table-download-link-wrapper">
              <div className="ezev-table-download-link" onClick={()=>candidatesByTab(currentTab)}
                   target="_blank"
              >
                <p className="ezev-table-download-link-text">Download</p>
              </div>
            </div>
          </div>
          <CustomAssumptionsTable 
            columns={EVCandidatesTableColumns} 
            data={selectedBevs}
            tableHeader={'Battery Electric Vehicles'}
            userSettings={props.userSettings}
          />
        </div>
        </S.TabInfo>
        }
        {currentTab === "phevs" &&
        <S.TabInfo>
          <div className="ezev-phev-table-wrapper">
            <h2 className="ezev-h2">Vehicles Selected</h2>
              <div className="ezev-tables-explanation-wrapper">
                <div className="ezev-tables-explanation ezEV-Table-Text-2">The following table list the details for each plug-in hybrid electric vehicle (PHEV) considered in the analysis.</div>
              <div className="ezev-table-download-link-wrapper">
            <div className="ezev-table-download-link" onClick={()=>candidatesByTab(currentTab)}
                 target="_blank"
            >
              <p className="ezev-table-download-link-text">Download</p>
            </div>
            </div>
          </div>
          <CustomAssumptionsTable
            columns={EVCandidatesTableColumns}
            data={selectedPhevs}
            tableHeader={'Plug-In Hybrid Electric Vehicles'}
            userSettings={props.userSettings}
          />
        </div>
        </S.TabInfo>
        }
       
        {currentTab === "rates" &&
          <S.RatesTabInfo>
            <RatesTable kwhRates={kwhRates} user={props.user} settings={props.settings} userSettings={props.userSettings}/>
          </S.RatesTabInfo>
        }
      </div>
    </div>
  </div>
  )
  }
}

export default AssumptionsView;
